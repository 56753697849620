import './App.css';
import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import NotFound from "./components/NotFound";
import Settings from "./components/Settings";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import Settings2 from "./components/Settings2";
import keycloak from "./keycloak";
import NavBar from "./components/NavBar";
import PrivateRoute from "./helpers/PrivateRoute";
import ImportSettings from "./components/ImportSettings";

// const url = process.env.REACT_APP_BACKEND_URL
// TODO: tijdelijke oplossing, omdat .env files niet werken
const url = "https://api.ussptest.utmeurope.eu"

function App() {
    useEffect(() => {
        let interval = setInterval(async () => {
                const t = new Date();
                t.setSeconds(t.getSeconds() - 30);
                const start_date = `2024-05-06 07:59:54`
                const end_date = `2024-05-06 08:01:00`
                // const start_date = t.toISOString()
                //     .replace('T', ' ').replace('Z', ' ').substring(0, 19);
                // const end_date = new Date()
                //     .toISOString()
                //     .replace('T', ' ').replace('Z', ' ').substring(0, 19);

                // console.log("Start date: " + start_date);
                // console.log("End date: " + end_date);

                // version 1
                // const response = await fetch(`https://remoteid.holdingthedrones.com/getdronehistoryperiod/"${start_date}"/"${end_date}"`)

                // version 2
                const response = await fetch(`https://remoteid.holdingthedrones.com/getdronehistoryperiod2/"${start_date}"/"${end_date}"`)

                let data;

                if (response.ok) {
                    data = await response.json();
                }

                const response2 = await fetch(`${url}/v2/flightdata`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data.dronedata[0])
                })
            },
            1000 * 60) // 1 minute
        return () => clearInterval(interval);
    }, []);

    return (
        <ReactKeycloakProvider authClient={keycloak}>
            <NavBar/>
            <Routes>
                <Route exact path="/" element={<Dashboard/>}/>
                <Route exact path="/importSettings" element={<PrivateRoute><ImportSettings/></PrivateRoute>}/>
                <Route exact path="/settings" element={<PrivateRoute><Settings/></PrivateRoute>}/>
                <Route exact path="/settings2" element={<PrivateRoute><Settings2/></PrivateRoute>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </ReactKeycloakProvider>
    );
}

export default App;
