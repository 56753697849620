import React from "react";
import "./navbar.css"
import {Link} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";

function NavBar() {
    const {keycloak} = useKeycloak()

    return (
        <nav className="navbar">
            <Link to="/" className="navItem">Dashboard</Link>
            <Link to="/importSettings" className="navItem">Import Settings</Link>
            <div className="dropdown">
                <div className="navItem">Export Settings</div>
                <div className="dropdown-content">
                    <Link to="/settings" className="navItem">Requirement Settings</Link>
                    <Link to="/settings2" className="navItem">Export Settings</Link>
                </div>
            </div>
            {!keycloak.authenticated && (
                <div className="navItem" onClick={() => keycloak.login()}>Login</div>
            )}
            {!!keycloak.authenticated && (
                <div className="navItem" onClick={() => keycloak.logout()}>Logout</div>
            )}
        </nav>
    )
}

export default NavBar;