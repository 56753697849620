import React, {useEffect, useState} from "react";
import "./dashboard.css"
import {useKeycloak} from "@react-keycloak/web";

// const url = process.env.REACT_APP_BACKEND_URL
// TODO: tijdelijke oplossing, omdat .env files niet werken
const url = "https://api.ussptest.utmeurope.eu"

function Dashboard() {
    const [dronedata, setDronedata] = useState(false);
    const [flightData, setFlightData] = useState(false);
    const [approvedDroneData, setApprovedDroneData] = useState(0);
    const [rejectedDroneData, setRejectedDroneData] = useState(0);
    const {keycloak} = useKeycloak();

    const getDroneData = async () => {
        const response = await fetch("https://remoteid.holdingthedrones.com/getdronedata")

        if (response.ok) {
            const data = await response.json();
            setDronedata(data.dronedata)
        }
    };

    const getFlightData = async () => {
        const t = new Date();
        t.setHours(t.getHours() - 168); // Last 7 days
        const start_date = `2024-05-06 07:59:54`
        const end_date = `2024-05-06 08:01:00`
        // const start_date = t.toISOString()
        //     .replace('T', ' ').replace('Z', ' ').substring(0, 19);
        // const end_date = new Date()
        //     .toISOString()
        //     .replace('T', ' ').replace('Z', ' ').substring(0, 19);

        // console.log("Start date: " + start_date);
        // console.log("End date: " + end_date);

        // version 1
        // const response = await fetch(`https://remoteid.holdingthedrones.com/getdronehistoryperiod/"${start_date}"/"${end_date}"`)

        // version 2
        const response = await fetch(`https://remoteid.holdingthedrones.com/getdronehistoryperiod2/"${start_date}"/"${end_date}"`)

        if (response.ok) {
            const data = await response.json();
            setFlightData(data.dronedata)
        }
    }

    async function getApprovedDroneData() {
        const response = await fetch(`${url}/amountApprovedDroneData`)

        if (response.ok) {
            const data = await response.json();
            setApprovedDroneData(data.amount)
        }
    }

    async function getRejectedDroneData() {
        const response = await fetch(`${url}/amountRejectedDroneData`)

        if (response.ok) {
            const data = await response.json();
            setRejectedDroneData(data.amount)
        }
    }

    useEffect(() => {
        getApprovedDroneData();
        getRejectedDroneData();

        let interval = setInterval(() => {
                getApprovedDroneData();
                getRejectedDroneData();
            },
            1000 * 60) // 60 seconds
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <h1 className="title">Welcome {keycloak?.idTokenParsed?.given_name ?? "User"}!</h1>
            <div className="container">
                <div className="rowBoxes">
                    <div className="box">
                        <h3 className="boxText">Aantal goedgekeurde drone data:</h3>
                        <h1 className="boxText">{approvedDroneData}</h1>
                    </div>
                    <div className="box">
                        <h3 className="boxText">Aantal afgekeurde drone data:</h3>
                        <h1 className="boxText">{rejectedDroneData}</h1>
                    </div>
                </div>
                <div>
                    <h3>Get the latest drone data</h3>
                    <button onClick={getDroneData} className="btn">Receive</button>
                    {dronedata && dronedata.map(data => {
                        const {
                            location_id, type, speed, drone_id, latitude, longitude, altitude, heading, drone_time,
                            time
                        } = data
                        return <div key={data}>
                            <div>Location id: {location_id}</div>
                            <div>Type: {type}</div>
                            <div>Speed: {speed}</div>
                            <div>Drone id: {drone_id}</div>
                            <div>Latitude: {latitude}</div>
                            <div>Longitude: {longitude}</div>
                            <div>Altitude: {altitude}</div>
                            <div>Heading: {heading}</div>
                            <div>Drone time: {drone_time}</div>
                            <div>Time: {time.replace('T', ' ').replace('Z', ' ').substring(0, 19)}</div>
                        </div>
                    })}
                </div>
                <div>
                    <h3>Get the latest flight data</h3>
                    <button className="btn" onClick={getFlightData}>Receive</button>
                    {flightData && <table>
                        <thead>
                        <tr>
                            {/* This is version 1 */}
                            {/*<th>altitude</th>*/}
                            {/*<th>drone_id</th>*/}
                            {/*<th>drone_time</th>*/}
                            {/*<th>heading</th>*/}
                            {/*<th>latitude</th>*/}
                            {/*<th>location_id</th>*/}
                            {/*<th>longitude</th>*/}
                            {/*<th>speed</th>*/}
                            {/*<th>time</th>*/}
                            {/*<th>type</th>*/}

                            {/* This is version 2 */}
                            <th>Id</th>
                            <th>Drone_Id</th>
                            <th>Trip_Id</th>
                            <th>Speed</th>
                            <th>Latitude</th>
                            <th>Longitude</th>
                            <th>Alt_GPS</th>
                            <th>Alt_Pres</th>
                            <th>Heading</th>
                            <th>Drone_Time</th>
                            <th>Time</th>
                            <th>Humitidy</th>
                            <th>Temp</th>
                            <th>Press</th>
                            <th>Yaw</th>
                            <th>Roll</th>
                            <th>Pitch</th>
                            <th>Bat_Volt</th>
                            <th>Bat_Perc</th>
                            <th>RSSI</th>
                        </tr>
                        </thead>
                        <tbody>
                        {flightData.map(data => {
                            // Version 1
                            // const {
                            //     altitude, drone_id, drone_time, heading, latitude, location_id, longitude,
                            //     speed, time, type
                            // } = data

                            // Version 2
                            const {
                                MSR_Id, MSR_Drone_Id, MSR_Trip_Id, MSR_Speed, MSR_Latitude, MSR_Longitude,
                                MSR_Alt_GPS, MSR_Alt_Pres, MSR_Heading, MSR_Drone_Time, MSR_Time, MSR_Humitidy,
                                MSR_Temp, MSR_Press, MSR_Yaw, MSR_Roll, MSR_Pitch, MSR_Bat_Volt, MSR_Bat_Perc,
                                MSR_RSSI
                            } = data
                            return <tr key={data}>
                                {/* This is version 1 */}
                                {/*<td>{altitude}</td>*/}
                                {/*<td>{drone_id}</td>*/}
                                {/*<td>{drone_time}</td>*/}
                                {/*<td>{heading}</td>*/}
                                {/*<td>{latitude}</td>*/}
                                {/*<td>{location_id}</td>*/}
                                {/*<td>{longitude}</td>*/}
                                {/*<td>{speed}</td>*/}
                                {/*<td>{time}</td>*/}
                                {/*<td>{type}</td>*/}

                                {/* This is version 2 */}
                                <td>{MSR_Id}</td>
                                <td>{MSR_Drone_Id}</td>
                                <td>{MSR_Trip_Id}</td>
                                <td>{MSR_Speed}</td>
                                <td>{MSR_Latitude}</td>
                                <td>{MSR_Longitude}</td>
                                <td>{MSR_Alt_GPS}</td>
                                <td>{MSR_Alt_Pres}</td>
                                <td>{MSR_Heading}</td>
                                <td>{MSR_Drone_Time.replace('T', ' ').replace('Z', ' ').substring(0, 19)}</td>
                                <td>{MSR_Time.replace('T', ' ').replace('Z', ' ').substring(0, 19)}</td>
                                <td>{MSR_Humitidy}</td>
                                <td>{MSR_Temp}</td>
                                <td>{MSR_Press}</td>
                                <td>{MSR_Yaw}</td>
                                <td>{MSR_Roll}</td>
                                <td>{MSR_Pitch}</td>
                                <td>{MSR_Bat_Volt}</td>
                                <td>{MSR_Bat_Perc}</td>
                                <td>{MSR_RSSI}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>}
                </div>
            </div>
        </>
    )
}

export default Dashboard;