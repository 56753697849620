// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    background-color: #D9D9D9;
}

.btn {
    border: none;
    padding: 20px;
    cursor: pointer;
    color: white;
    background-color: #FF4B4B;
    border-radius: 5px;
    margin-bottom: 20px;
}

.btn:hover {
    background-color: #FF7C7C;
}

.delete-btn {
    margin: 15px 0 15px 0;
}

.formGroup {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
}

.input {
    padding: 10px;
    border-radius: 5px;
    border: 2px solid gray;
}

.row {
    display: flex;
    gap: 25px;
}

.provider {
    margin-bottom: 20px;
}

.label {
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;IACtB;;cAEU;IACV,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["body {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n    background-color: #D9D9D9;\n}\n\n.btn {\n    border: none;\n    padding: 20px;\n    cursor: pointer;\n    color: white;\n    background-color: #FF4B4B;\n    border-radius: 5px;\n    margin-bottom: 20px;\n}\n\n.btn:hover {\n    background-color: #FF7C7C;\n}\n\n.delete-btn {\n    margin: 15px 0 15px 0;\n}\n\n.formGroup {\n    margin-bottom: 20px;\n    display: flex;\n    flex-direction: column;\n    font-size: 1.2rem;\n}\n\n.input {\n    padding: 10px;\n    border-radius: 5px;\n    border: 2px solid gray;\n}\n\n.row {\n    display: flex;\n    gap: 25px;\n}\n\n.provider {\n    margin-bottom: 20px;\n}\n\n.label {\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
