// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formGroup {
    width: 250px;
}

form {
    display: flex;
    justify-content: space-evenly;
}

input[type=checkbox] {
    width: 18px;
    height: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/settings.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".formGroup {\r\n    width: 250px;\r\n}\r\n\r\nform {\r\n    display: flex;\r\n    justify-content: space-evenly;\r\n}\r\n\r\ninput[type=checkbox] {\r\n    width: 18px;\r\n    height: 18px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
