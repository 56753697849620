// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
}

.title {
    margin-left: 25px;
}

td, th {
    border: 1px solid black;
    padding: 4px;
    min-width: 30px;
}

th {
    background-color: #FF7C7C;
}

table {
    border-collapse: collapse;
    margin-left: 25px;
    margin-bottom: 25px;
}

.rowBoxes {
    display: flex;
    justify-content: space-evenly;
}

.box {
    padding: 25px;
    background-color: #FF4B4B;
    border-radius: 15px;
    box-shadow: 0 0 1px black;
}

.boxText {
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-evenly;\r\n    text-align: center;\r\n}\r\n\r\n.title {\r\n    margin-left: 25px;\r\n}\r\n\r\ntd, th {\r\n    border: 1px solid black;\r\n    padding: 4px;\r\n    min-width: 30px;\r\n}\r\n\r\nth {\r\n    background-color: #FF7C7C;\r\n}\r\n\r\ntable {\r\n    border-collapse: collapse;\r\n    margin-left: 25px;\r\n    margin-bottom: 25px;\r\n}\r\n\r\n.rowBoxes {\r\n    display: flex;\r\n    justify-content: space-evenly;\r\n}\r\n\r\n.box {\r\n    padding: 25px;\r\n    background-color: #FF4B4B;\r\n    border-radius: 15px;\r\n    box-shadow: 0 0 1px black;\r\n}\r\n\r\n.boxText {\r\n    color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
