import React, {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";

// const url = process.env.REACT_APP_BACKEND_URL
// TODO: tijdelijke oplossing, omdat .env files niet werken
const url = "https://api.ussptest.utmeurope.eu"

function Settings2() {
    const [fields, setFields] = useState(false);
    const [message, setMessage] = useState("");
    const [rows, setRows] = useState([])
    const [providers, setProviders] = useState([])
    const [provider, setProvider] = useState(false)
    const [exampleData, setExampleData] = useState(false)
    const {keycloak} = useKeycloak();

    const getFields = async () => {
        const start_date = `2024-02-16 12:59:54`
        const end_date = `2024-02-18 12:59:54`

        // version 1
        // const response = await fetch(`https://remoteid.holdingthedrones.com/getdronehistoryperiod/"${start_date}"/"${end_date}"`)

        // version 2
        const response = await fetch(`https://remoteid.holdingthedrones.com/getdronehistoryperiod2/"${start_date}"/"${end_date}"`)

        if (response.ok) {
            const data = await response.json();
            const keys = Object.keys(data.dronedata[0])
            setFields(keys)
        }
    }

    const getProviders = async () => {
        const response = await fetch(`${url}/provider`)

        if (response.ok) {
            const data = await response.json();
            setProviders(data);
        }
    }

    function handleChange(event) {
        const newState = rows.map(row => {
            if (row.count === Number.parseInt(event.target.name)) {
                if (event.target.id === "key") {
                    return {...row, "key": event.target.value}
                }

                if (event.target.id === "value") {
                    return {...row, "value": event.target.value}
                }

                if (event.target.id === "datatype") {
                    return {...row, "datatype": event.target.value}
                }
            }

            return row;
        })

        setRows(newState)
    }

    function changeProvider(event) {
        setProvider(event.target.value)
        const provider_param = event.target.value;
        getSettings(provider_param)
    }

    async function getSettings(provider) {
        const response = await fetch(`${url}/settings2/${provider}`)

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        let values = [];

        if (data.length !== 0) {
            values = Object.values(data[0])
        }

        const rows = [];
        let rowsCount = 1;
        let count = 1;

        for (let i = 0; i < 13; i++) {
            if (values[count] !== "undefined" && values[count] !== undefined) {
                const key = values[count];
                const value = values[count + 1];
                const datatype = values[count + 2]
                rows.push({count: rowsCount, key: key, value: value, datatype: datatype})
                rowsCount++;
            }

            count += 3
        }

        setRows(rows)
    }

    async function saveSettings(e) {
        e.preventDefault();

        const data = {}

        data.user_id = keycloak.subject;
        data.provider_name = provider;

        for (const row of rows) {
            data[`field${row.count}_name`] = row.key;
            data[`field${row.count}_value`] = row.value;
            data[`field${row.count}_datatype`] = row.datatype;
        }

        console.log(data);

        const response = await fetch(`${url}/settings2`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })

        if (response.ok) {
            setMessage("Settings saved successfully!")
        } else {
            setMessage("Error while saving settings...")
        }
    }

    function addRow() {
        if (rows.length >= 13) {
            console.log("Maximum length reached")
            return;
        }

        setRows(prevState => {
            const newState = []
            let i = 1;
            prevState.forEach(row => {
                if (row.count === i) {
                    newState.push(row);
                } else {
                    newState.push({count: i})
                }

                i++;
            })

            return [...newState, {count: prevState.length + 1}]
        })
    }

    function deleteRow(e) {
        setRows(prevState => {
            const newState = []
            let i = 1;

            prevState.filter(row => row.count !== Number.parseInt(e.target.value)).forEach(value => {
                // Reset numbers so there is no 1, 3, 4 but 1, 2, 3 when row 2 is deleted
                if (value.count !== i) {
                    newState.push({count: i});
                } else {
                    newState.push(value)
                }

                i++;
            })

            return newState;
        })
    }

    async function showExample() {
        const t = new Date();
        t.setSeconds(t.getSeconds() - 30);
        const start_date = `2024-05-06 07:59:54`
        const end_date = `2024-05-06 08:01:00`
        // const start_date = t.toISOString()
        //     .replace('T', ' ').replace('Z', ' ').substring(0, 19);
        // const end_date = new Date()
        //     .toISOString()
        //     .replace('T', ' ').replace('Z', ' ').substring(0, 19);

        // version 1
        // const response = await fetch(`https://remoteid.holdingthedrones.com/getdronehistoryperiod/"${start_date}"/"${end_date}"`)

        // version 2
        const response = await fetch(`https://remoteid.holdingthedrones.com/getdronehistoryperiod2/"${start_date}"/"${end_date}"`)

        let data;

        if (response.ok) {
            data = await response.json();
        }

        const response2 = await fetch(`${url}/v2/flightdata`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data.dronedata[0])
        })

        if (response2.ok) {
            const data = await response2.json();
            setExampleData(data)
        }
    }

    useEffect(() => {
        getFields();
        getProviders();
    }, []);

    return <div className="container">
        <h1>Export Settings</h1>
        <h2>Settings format for sending drone data to a provider</h2>
        <div className="provider">
            <label className="label">Select a provider</label>
            <select
                className="input"
                onChange={changeProvider}>
                <option selected disabled>Select an option</option>
                {providers.map(provider => {
                    return <option key={provider} value={provider.Provider_name}>{provider.Provider_name}</option>
                })}
            </select>
        </div>
        {provider &&
            <>
                <div>
                    <button type="button" className="btn" onClick={addRow}>Add row</button>
                </div>
                <form>
                    <div>
                        {rows.map(row => {
                            return <div className="row" key={row.count}>
                                <div className="formGroup">
                                    <label>Field {row.count} Name</label>
                                    <input
                                        type="text"
                                        className="input"
                                        id="key"
                                        onChange={handleChange}
                                        value={row.key}
                                        name={row.count}/>
                                </div>
                                <div className="formGroup">
                                    <label>Value {row.count}</label>
                                    <select
                                        className="input"
                                        id="value"
                                        onChange={handleChange}
                                        value={row.value}
                                        name={row.count}>
                                        <option selected disabled>Select an option</option>
                                        {fields && fields.map(field => {
                                            return <option key={field} value={field}>{field}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="formGroup">
                                    <label>Data type {row.count}</label>
                                    <select
                                        className="input"
                                        id="datatype"
                                        onChange={handleChange}
                                        value={row.datatype}
                                        name={row.count}>
                                        <option selected disabled>Select an option</option>
                                        <option value="number">Number</option>
                                        <option value="string">String</option>
                                    </select>
                                </div>
                                <button type="button" className="btn delete-btn" onClick={deleteRow} value={row.count}>
                                    <img src="/images/trash_bin_icon.png" alt="bin" width="20"/>
                                </button>
                            </div>
                        })}
                        <button type="submit" className="btn" onClick={saveSettings}>Save</button>
                        <div>
                            <small className="message">{message}</small>
                        </div>
                    </div>
                </form>
                <div>
                    <button className="btn" onClick={showExample}>Show example</button>
                </div>
                {exampleData &&
                        <div>{JSON.stringify(exampleData)}</div>
                }
            </>
        }
    </div>
}

export default Settings2;