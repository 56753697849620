import "./settings.css"
import React, {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";

// const url = process.env.REACT_APP_BACKEND_URL
// TODO: tijdelijke oplossing, omdat .env files niet werken
const url = "https://api.ussptest.utmeurope.eu"

function Settings() {
    const [formData, setFormData] = useState({});
    const [message, setMessage] = useState("");
    const [providers, setProviders] = useState([])
    const [provider, setProvider] = useState(false)
    const {keycloak} = useKeycloak();

    async function saveSettings(e) {
        e.preventDefault();

        let allInputsAreFilledIn = true;

        document.querySelectorAll(".input").forEach(input => {
            if (input.value === "") {
                allInputsAreFilledIn = false;
            }
        })

        if (!allInputsAreFilledIn) {
            alert("Please fill in all inputs!")
            return;
        }

        let data = formData;
        data.provider_name = provider

        const response = await fetch(`${url}/settings`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })

        if (response.ok) {
            setMessage("Settings saved successfully!")
        } else {
            setMessage("Error while saving settings...")
        }
    }

    const getProviders = async () => {
        const response = await fetch(`${url}/provider`)

        if (response.ok) {
            const data = await response.json();
            setProviders(data);
        }
    }

    function changeProvider(event) {
        setProvider(event.target.value)
        setMessage("")
        const provider_param = event.target.value;
        getLatestSettings(provider_param);
    }

    async function getLatestSettings(provider) {
        const response = await fetch(`${url}/settings/${provider}`);

        if (response.ok) {
            const data = await response.json();

            if (data.length !== 0) {
                setFormData(data[0])
            } else {
                setFormData({
                    S_minimum_temp: "",
                    S_maximum_temp: "",
                    S_maximum_speed: "",
                    S_production_mode: "",
                    S_minimum_latitude: "",
                    S_maximum_latitude: "",
                    S_minimum_longitude: "",
                    S_maximum_longitude: "",
                    provider_name: provider
                })
            }
        }
    }

    function handleChange(event) {
        setFormData(prevState => {
            return {
                ...prevState,
                [event.target.name]: event.target.value,
                S_entryId: keycloak.subject
            }
        })
    }

    useEffect(() => {
        getProviders();
    }, []);

    return (
        <div className="container">
            <h1>Requirement Settings</h1>
            <h2>Settings for drones when they fly</h2>
            <div className="provider">
                <label className="label">Select a provider</label>
                <select
                    className="input"
                    onChange={changeProvider}>
                    <option selected disabled>Select an option</option>
                    {providers.map(provider => {
                        return <option key={provider} value={provider.Provider_name}>{provider.Provider_name}</option>
                    })}
                </select>
            </div>
            {provider &&
                <form>
                    <div className="column">
                        <div className="formGroup">
                            <label>Minimum Temperature</label>
                            <input type="number"
                                   className="input"
                                   onChange={handleChange}
                                   value={formData.S_minimum_temp}
                                   name="S_minimum_temp"/>
                        </div>
                        <div className="formGroup">
                            <label>Maximum Temperature</label>
                            <input type="number"
                                   className="input"
                                   onChange={handleChange}
                                   value={formData.S_maximum_temp}
                                   name="S_maximum_temp"/>
                        </div>
                        <div className="formGroup">
                            <label>Maximum Speed</label>
                            <input
                                type="number"
                                className="input"
                                onChange={handleChange}
                                value={formData.S_maximum_speed}
                                name="S_maximum_speed"/>
                        </div>
                        <div className="formGroup">
                            <label>Production mode</label>
                            <input
                                type="checkbox"
                                onChange={event => {
                                    setFormData(prevState => {
                                        return {
                                            ...prevState,
                                            [event.target.name]: event.target.checked
                                        }
                                    })
                                    console.log(event.target.checked)
                                }}
                                value={formData.S_production_mode}
                                name="S_production_mode"/>
                        </div>
                    </div>
                    <div className="column">
                        <div className="formGroup">
                            <label>Minimum Latitude</label>
                            <input
                                type="number"
                                className="input"
                                onChange={handleChange}
                                value={formData.S_minimum_latitude}
                                name="S_minimum_latitude"/>
                        </div>
                        <div className="formGroup">
                            <label>Maximum Latitude</label>
                            <input
                                type="number"
                                className="input"
                                onChange={handleChange}
                                value={formData.S_maximum_latitude}
                                name="S_maximum_latitude"/>
                        </div>
                        <div className="formGroup">
                            <label>Minimum Longitude</label>
                            <input
                                type="number"
                                className="input"
                                onChange={handleChange}
                                value={formData.S_minimum_longitude}
                                name="S_minimum_longitude"/>
                        </div>
                        <div className="formGroup">
                            <label>Maximum Longitude</label>
                            <input required
                                   type="number"
                                   className="input"
                                   onChange={handleChange}
                                   value={formData.S_maximum_longitude}
                                   name="S_maximum_longitude"
                            />
                        </div>
                        <div>
                            <button type="submit" className="btn" onClick={saveSettings}>Save</button>
                        </div>
                        <div>
                            <small className="message">{message}</small>
                        </div>
                    </div>
                </form>
            }
        </div>
    )
}

export default Settings;