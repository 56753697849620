import React, {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";

// const url = process.env.REACT_APP_BACKEND_URL
// TODO: tijdelijke oplossing, omdat .env files niet werken
const url = "https://api.ussptest.utmeurope.eu"

function ImportSettings() {
    const [data, setData] = useState([]);
    const [showExample, setShowExample] = useState(false);
    const [providers, setProviders] = useState([])
    const [provider, setProvider] = useState(false)
    const [message, setMessage] = useState("");
    const {keycloak} = useKeycloak();

    const getProviders = async () => {
        const response = await fetch(`${url}/provider`)

        if (response.ok) {
            const data = await response.json();
            setProviders(data);
        }
    }

    function changeProvider(event) {
        setProvider(event.target.value)
        setMessage("")
        const provider_param = event.target.value;
    }

    function handleChange(event) {
        const file = event.target;

        let reader = new FileReader();

        reader.onload = function () {
            const text = reader.result;
            let array = text.split('\n');
            setData(array)
        };
        reader.readAsText(file.files[0]);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (data.length === 0) {
            alert("Please select a file first")
            return;
        }

        let array = [];
        let keys = data[0].split(',');

        for (let i = 1; i < data.length; i++) {
            let object = {};
            let values = data[i].split(',')
            for (let j = 0; j < values.length; j++) {
                object[keys[j]] = values[j];
            }
            array.push(object);
        }

        const response = await fetch(`${url}/import/${provider}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(array)
        })

        if (response.ok) {
            setMessage("Drone data saved successfully!")
        } else {
            setMessage("Error while saving drone data...")
        }
    }

    useEffect(() => {
        getProviders();
    }, []);

    return (
        <div className="container">
            <h1>Import Settings</h1>
            <h2>Settings for importing drone data through a .csv file</h2>
            <div className="provider">
                <label className="label">Select a provider</label>
                <select
                    className="input"
                    onChange={changeProvider}>
                    <option selected disabled>Select an option</option>
                    {providers.map(provider => {
                        return <option key={provider} value={provider.Provider_name}>{provider.Provider_name}</option>
                    })}
                </select>
            </div>
            {provider &&
            <form>
                <div className="column">
                    <div className="formGroup">
                        <label>Import your .csv file here</label>
                        <input type="file"
                               className="input"
                               id="file"
                               accept="text/csv"
                               onChange={handleChange}/>
                    </div>
                    <div className="row">
                        <div>
                            <button type="submit"
                                    className="btn"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        if (data.length === 0) {
                                            alert("Please select a file first")
                                            return;
                                        }
                                        setShowExample(prevState => !prevState)
                                    }}>
                                {!showExample ? "Show example" : "Hide example"}
                            </button>
                        </div>
                        <div>
                            <button type="submit" className="btn" onClick={handleSubmit}>Save</button>
                        </div>
                    </div>
                    <small className="message">{message}</small>
                </div>
            </form>
            }
            <table>
                <tbody>
                {showExample && data.map(value => {
                    let array = value.split(',')
                    return <tr key={value}>{array.map(value1 => <td key={value1}>{value1}</td>)}</tr>
                })}
                </tbody>
            </table>
        </div>
    )
}

export default ImportSettings;