// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    display: flex;
    justify-content: space-between;
    background-color: #FF7C7C;
}

.navItem {
    text-decoration: none;
    padding: 20px;
    color: white;
    cursor: pointer;
}

/* The dropdown container */
.dropdown {
    float: left;
    overflow: hidden;
}

/* Dropdown button */
.dropdown .dropbtn {
    border: none;
    outline: none;
    color: white;
    background-color: inherit;
    margin: 0; /* Important for vertical align on mobile phones */
}

/* Dropdown content (hidden by default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #FF7C7C;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    float: none;
    color: white;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
    background-color: #FF8C8C;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,YAAY;IACZ,eAAe;AACnB;;AAEA,2BAA2B;AAC3B;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA,oBAAoB;AACpB;IACI,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,yBAAyB;IACzB,SAAS,EAAE,kDAAkD;AACjE;;AAEA,yCAAyC;AACzC;IACI,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,wCAAwC;IACxC,UAAU;AACd;;AAEA,8BAA8B;AAC9B;IACI,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,cAAc;IACd,gBAAgB;AACpB;;AAEA,2DAA2D;AAC3D;IACI,yBAAyB;AAC7B;;AAEA,oCAAoC;AACpC;IACI,cAAc;AAClB","sourcesContent":[".navbar {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    background-color: #FF7C7C;\r\n}\r\n\r\n.navItem {\r\n    text-decoration: none;\r\n    padding: 20px;\r\n    color: white;\r\n    cursor: pointer;\r\n}\r\n\r\n/* The dropdown container */\r\n.dropdown {\r\n    float: left;\r\n    overflow: hidden;\r\n}\r\n\r\n/* Dropdown button */\r\n.dropdown .dropbtn {\r\n    border: none;\r\n    outline: none;\r\n    color: white;\r\n    background-color: inherit;\r\n    margin: 0; /* Important for vertical align on mobile phones */\r\n}\r\n\r\n/* Dropdown content (hidden by default) */\r\n.dropdown-content {\r\n    display: none;\r\n    position: absolute;\r\n    background-color: #FF7C7C;\r\n    min-width: 160px;\r\n    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);\r\n    z-index: 1;\r\n}\r\n\r\n/* Links inside the dropdown */\r\n.dropdown-content a {\r\n    float: none;\r\n    color: white;\r\n    text-decoration: none;\r\n    display: block;\r\n    text-align: left;\r\n}\r\n\r\n/* Add a grey background color to dropdown links on hover */\r\n.dropdown-content a:hover {\r\n    background-color: #FF8C8C;\r\n}\r\n\r\n/* Show the dropdown menu on hover */\r\n.dropdown:hover .dropdown-content {\r\n    display: block;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
